<template>
  <div class="hardware-container">
    <div class="banner">
      <img src="@/assets/global/hardware/banner.png" alt="banner">
    </div>
    <div class="hardware-order">
      <!-- //接口数据 -->
      <div v-for="(item, index) in productList" :key="index" class="hardware-item">
        <div>
          <p>{{item.productName}}</p>
          <p>{{item.productSummary}}</p>
        </div>
        <img :src="item.productPhotoUrl" alt="">
      </div>
      
    </div>
  </div>
  
</template>

<script>
  export default {
    data() {
      return {
        productList: []
      };
    },

    mounted() {
      this.getProductList();
    },
    
    methods: {
      async getProductList() {
        let result = await this.$http.get({
            url: '/client/web/product/list'
        })
        
        let { rows } = result;
        this.productList = [...rows];
      },
    },
    
  }
</script>

<style lang="scss" scoped>
  .hardware-container {
    .banner {
      > img {
        width: 100%;
      }
    }
    .hardware-order {
      margin-top: .24rem;
      padding: 0 .16rem;
      margin-bottom: .36rem;
      .hardware-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 1.72rem;
        background-color: rgba(0, 173, 246, 0.04);
        border-radius: .08rem;
        padding: .24rem .16rem;
        margin-bottom: .12rem;
        > div {
          width: 2.13rem;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          > p {
            &:nth-child(1) {
              font-size: .15rem;
              font-weight: 500;
              line-height: .22rem;
              color: rgba(0, 0, 0, 0.85);
              margin-bottom: .08rem;
            }
            &:nth-child(2) {
              font-size: .13rem;
              font-weight: 400;
              line-height: .234rem;
              color: rgba(0, 0, 0, 0.6);

            }
          }
        }
        > img {
          height: .9rem;
          width: .9rem;
        }
      }
    }
  }
</style>